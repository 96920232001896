import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import DashboardService from "../../services/DashboardService";

export const getSubscriptionsStatisticDashboardByUserId = createAsyncThunk(
    'application/getSubscriptionsByUserId',
    async (year, { rejectWithValue }) => {
        try {
            const response = await DashboardService.getSubscriptionsStatisticDashboardByUserId(year);
            // console.log(response);
            return response;
        } catch (err) {
            // handle or throw error
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);


const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        dashboards: [],
        transaction: [],
        loading: false,
        error: null,
    },
    reducers: {
        // your other reducers here
    },
    extraReducers: (builder)=> {
        builder
            .addCase(getSubscriptionsStatisticDashboardByUserId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSubscriptionsStatisticDashboardByUserId.fulfilled, (state, action) => {
                state.loading = false;
                state.dashboards = action.payload;
            })

            .addCase(getSubscriptionsStatisticDashboardByUserId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
})

export default DashboardSlice.reducer;