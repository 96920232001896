import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import FinancialsService from "../../services/FinancialsService";
import ApplicationService from "../../services/ApplicationService";


export const getPaymentMethods = createAsyncThunk('UserCustomPaymentMethod/get-all-payments-methods', async (data, { rejectWithValue }) => {
    try {
        const response =  await FinancialsService.getPaymentMethods();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getUserBank = createAsyncThunk('UserBank/get-user-banks', async (data, { rejectWithValue }) => {
    try {
        const response =  await FinancialsService.getUserBank();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getSource = createAsyncThunk('source/get-user-source', async (data, { rejectWithValue }) => {
    try {
        const response =  await FinancialsService.getSource();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getCardsByUserId = createAsyncThunk('cards/get-user-cards', async (data, { rejectWithValue }) => {
    try {
        const response =  await FinancialsService.getCardsByUserId();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const postCard = createAsyncThunk('cards-post/post-user-cards', async (data, { rejectWithValue }) => {
    try {
        const response =  await FinancialsService.postCard(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});


export const getTransaction = createAsyncThunk('Transaction/get',
    async ({userBank}, { rejectWithValue }) => {
    try {
        const response =  await FinancialsService.getTransaction({userBankId: userBank});
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const ChangeTransactionVisibility = createAsyncThunk(
    'СhangeTransactionVisibility/put',
    async ({transactionId, isHidden}, { rejectWithValue }) => {
        try {
            const response = await FinancialsService.ChangeTransactionVisibility(
                {transactionId: transactionId, isHidden: isHidden});
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
);

export const getTransactionByUserId = createAsyncThunk('TransactionByUserId/get',
    async ( { rejectWithValue }) => {
        try {
            const response =  await FinancialsService.getTransactionByUserId();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    });

export const getAllTransactionUser = createAsyncThunk('AllTransactionUser/get',
    async ( { rejectWithValue }) => {
        try {
            const response =  await FinancialsService.getAllTransactionUser();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    });

export const deleteCard = createAsyncThunk(
    'delete-card/delete-card-by-id',
    async (data, { rejectWithValue }) => {
        try {
            const response = await FinancialsService.deleteCard(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error deleting the subscription');
        }
    }
);

export const deleteAllDataByCardId = createAsyncThunk(
    'delete-all-data/delete-data-by-card-id',
    async (data, { rejectWithValue }) => {
        try {
            const response = await FinancialsService.deleteAllDataByCardId(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error deleting the subscription');
        }
    }
);

export const editCard = createAsyncThunk(
    'card-edit/edit',
    async (data, { rejectWithValue }) => {
        try {
            const response = await FinancialsService.editCard(data);
            return response;
        } catch (err) {
            // console.log(err);
            return rejectWithValue(err.response?.data?.message || 'Error edit the subscription');
        }
    }
);


const FinancialsSlice = createSlice({
    name: 'financials',
    initialState: {
        financials: [],
        bank: [],
        transaction: [],
        cards: [],
        transactionByUserId: [],
        allTransactionUser: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(getPaymentMethods.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getPaymentMethods.fulfilled, (state, action) => {
                state.loading = false
                state.financials = action.payload;
            })
            .addCase(getPaymentMethods.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(editCard.pending, (state)=> {
                state.loading = true;
            })
            .addCase(editCard.fulfilled, (state, action) => {
                state.loading = false
                state.financials = action.payload;
            })
            .addCase(editCard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(deleteCard.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deleteCard.fulfilled, (state, action) => {
                state.loading = false
                state.cardId = action.payload;
            })
            .addCase(deleteCard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deleteAllDataByCardId.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deleteAllDataByCardId.fulfilled, (state, action) => {
                state.loading = false
                state.cardId = action.payload;
            })
            .addCase(deleteAllDataByCardId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(postCard.pending, (state)=> {
                state.loading = true;
            })
            .addCase(postCard.fulfilled, (state, action) => {
                state.loading = false
                state.financials = action.payload;
            })
            .addCase(postCard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            .addCase(getCardsByUserId.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getCardsByUserId.fulfilled, (state, action) => {
                state.loading = false
                state.cards = action.payload;
            })
            .addCase(getCardsByUserId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getUserBank.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getUserBank.fulfilled, (state, action) => {
                state.loading = false
                state.bank = action.payload;
            })
            .addCase(getUserBank.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getSource.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getSource.fulfilled, (state, action) => {
                state.loading = false
                state.source = action.payload;
            })
            .addCase(getSource.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getTransaction.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getTransaction.fulfilled, (state, action) => {
                state.loading = false
                state.transaction = action.payload;
            })
            .addCase(getTransaction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(ChangeTransactionVisibility.pending, (state)=> {
                state.loading = true;
            })
            .addCase(ChangeTransactionVisibility.fulfilled, (state, action) => {
                state.loading = false
                state.transaction = action.payload;
            })
            .addCase(ChangeTransactionVisibility.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getTransactionByUserId.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getTransactionByUserId.fulfilled, (state, action) => {
                state.loading = false
                state.transactionByUserId = action.payload;
            })
            .addCase(getTransactionByUserId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllTransactionUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllTransactionUser.fulfilled, (state, action) => {
                state.loading = false
                state.allTransactionUser = action.payload;
            })
            .addCase(getAllTransactionUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },

});

export default FinancialsSlice.reducer;
