import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import ApplicationService from 'services/ApplicationService';
import { AUTH_TOKEN } from "../../constants/AuthConstant";
import {signIn} from "./authSlice";
import AddSubscriptionService from "../../services/AddSubscriptionService";
import DashboardService from "../../services/DashboardService";
import FinancialsService from "../../services/FinancialsService";

// Async thunk for getting subscriptions
export const getSubscriptions = createAsyncThunk(
    'application/getSubscriptions',
    async (data, { rejectWithValue }) => {
        try {
            const response = await ApplicationService.getSubscriptions();
            return response.subscriptions;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const getSubscriptionsNotPaid = createAsyncThunk(
    'application/getSubscriptionsNotPaid',
    async (data, { rejectWithValue }) => {
        try {
            const response = await ApplicationService.getSubscriptionsNotPaid();
            return response.subscriptions;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const getAllCategories = createAsyncThunk(
    'get-all-categories-sub/getSubscriptions',
    async (data, { rejectWithValue }) => {
        try {
            const response = await ApplicationService.getAllCategories();

            return response[0].serviceSubCategories;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);

export const deleteSubscriptions = createAsyncThunk(
    'CustomSubscription/delete-by-id',
    async (data, { rejectWithValue }) => {
        try {
            const response = await ApplicationService.deleteSubscriptions(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error deleting the subscription');
        }
    }
);

export const getPriceAndDateTransactions = createAsyncThunk(
    'get-price/date-transactions',
    async (data, { rejectWithValue }) => {
        try {
            const response = await ApplicationService.getPriceAndDateTransactions(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error deleting the subscription');
        }
    }
);

export const ChangeSubscriptionVisibility = createAsyncThunk(
    'ChangeSubscriptionVisibility/put',
    async ({subscriptionId, isHidden}, { rejectWithValue }) => {
        try {
            const response = await ApplicationService.ChangeSubscriptionVisibility(
                {subscriptionId: subscriptionId, isHidden: isHidden});
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error')
        }
    }
);



export const editSubscriptions = createAsyncThunk(
    'CustomSubscription/edit',
    async (data, { rejectWithValue }) => {
        try {
            const response = await ApplicationService.editSubscriptions(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error edit the subscription');
        }
    }
);

export const getDetailsSubscription = createAsyncThunk(
    'getDetailsSubscription/ById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await ApplicationService.getDetailsSubscription(id);
            // console.log(response);
            return response;
        } catch (err) {
            // handle or throw error
            return rejectWithValue(err.response?.data?.message || 'Error fetching subscriptions');
        }
    }
);


const applicationSlice = createSlice({
    name: 'application',
    initialState: {
        subscriptions: [],
        subscriptionsNotpaid: [],
        priceAndDateTransaction: [],
        categories: [],
        loading: false,
        error: null,
    },
    reducers: {
        // your other reducers here
    },
    extraReducers: (builder)=> {
        builder
            .addCase(getSubscriptions.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getSubscriptions.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })

            .addCase(getSubscriptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(ChangeSubscriptionVisibility.pending, (state)=> {
                state.loading = true;
            })
            .addCase(ChangeSubscriptionVisibility.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })

            .addCase(ChangeSubscriptionVisibility.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getSubscriptionsNotPaid.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getSubscriptionsNotPaid.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptionsNotpaid = action.payload;
            })

            .addCase(getSubscriptionsNotPaid.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getDetailsSubscription.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getDetailsSubscription.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptions = action.payload;
            })

            .addCase(getDetailsSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllCategories.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllCategories.fulfilled, (state, action) => {
                state.loading = false
                state.categories = action.payload;
            })

            .addCase(getAllCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deleteSubscriptions.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deleteSubscriptions.fulfilled, (state, action) => {
                state.loading = false
                state.subscriptionId = action.payload;
            })

            .addCase(deleteSubscriptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getPriceAndDateTransactions.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getPriceAndDateTransactions.fulfilled, (state, action) => {
                state.loading = false
                state.priceAndDateTransaction = action.payload;
            })

            .addCase(getPriceAndDateTransactions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(editSubscriptions.pending, (state)=> {
                state.loading = true;
            })
            .addCase(editSubscriptions.fulfilled, (state, action) => {
                state.loading = false
                state.editSubscription = action.payload;
            })

            .addCase(editSubscriptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default applicationSlice.reducer

// export const { actions, reducer } = applicationSlice;
