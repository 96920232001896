import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import StatisticService from "../../services/StatisticService";
import {
    getBillingCycle,
    getCategory,
    getCurrency,
    getPaymentMethod,
    getRemindMe, getService, getSubscriptionType,
    postSubscriptions
} from "./addSubscriptionSlice";



// Transaction
export const monthlyTransactionSummary = createAsyncThunk(
    'get-monthly-transaction',
    async (year, { rejectWithValue }) => {
        try {
            const response = await StatisticService.monthlyTransactionSummary(year); // Передаємо рік як параметр

            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);


export const dailyTransactionSummary = createAsyncThunk('get-daily-transaction',
    async (month, {rejectWithValue}) => {
        try {
            const response = await StatisticService.dailyTransactionSummary(month);
            return response;
        }
        catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const getTransactionsTotalSpend = createAsyncThunk(
    'get-transaction-total-spend',
    async (data, {rejectWithValue}) => {
        try {
            const response = await StatisticService.getTransactionsTotalSpend();
            // console.log(response);
            return response;
        }
        catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);


// Subscription
export const monthlySubscriptionSummary = createAsyncThunk('get-monthly-subscription',
    async (year, {rejectWithValue}) => {
        try {
            const response = await StatisticService.monthlySubscriptionSummary(year);
            return response;
        }
        catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const dailySubscriptionSummary = createAsyncThunk('get-daily-subscription',
    async (month, { rejectWithValue }) => {
        try {
            const response = await StatisticService.dailySubscriptionSummary(month);

            return response;
        }
        catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);
export const getSubscriptionsEstimatedCostAndApplications = createAsyncThunk('get-transaction-estimated-cost',
    async (data, {rejectWithValue}) => {
        try {
            const response = await StatisticService.getSubscriptionsEstimatedCostAndApplications();
            // console.log(response);
            return response;
        }
        catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);


export const getStatisticSubscriptionsByYear = createAsyncThunk('get-statistic-subscription-by-year',
    async (year, {rejectWithValue}) => {
        try {
            const response = await StatisticService.getStatisticSubscriptionsByYear(year);
            return response;
        }
        catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const getStatisticYearByUserId = createAsyncThunk('get-statistic-year-by-userId',
    async (year, {rejectWithValue}) => {
        try {
            const response = await StatisticService.getStatisticYearByUserId(year);
            return response;
        }
        catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const getMonthlySubscriptionsStatisticByUserId = createAsyncThunk('get-monthly-subscription-statistic-year-by-userId',
    async (month, {rejectWithValue}) => {
        try {
            const response = await StatisticService.getMonthlySubscriptionsStatisticByUserId(month);
            return response;
        }
        catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const getStatisticSubscriptionsByMonth = createAsyncThunk('get-monthly-subscription-statistic-by-userId',
    async (month, {rejectWithValue}) => {
        try {
            const response = await StatisticService.getStatisticSubscriptionsByMonth(month);
            return response;
        }
        catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

const StatisticSlice = createSlice({
    name: 'statistic',
    initialState: {
        statistics: [],
        TransactionsTotalSpend: [],
        SubscriptionsEstimatedCostAndApplications: [],
        monthlyTransaction: [],
        monthlySubscription: [],
        dailyTransaction: [],
        dailySubscription: [],
        statisticSubscriptions: [],
        statisticYear: [],
        monthlySubscriptionsStatistic: [],
        byMonthlySubscriptionStatistic: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(monthlyTransactionSummary.pending, (state)=> {
                state.loading = true;
            })
            .addCase(monthlyTransactionSummary.fulfilled, (state, action) => {
                state.loading = false
                state.monthlyTransaction = action.payload;
            })
            .addCase(monthlyTransactionSummary.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(dailyTransactionSummary.pending, (state)=> {
                state.loading = true;
            })
            .addCase(dailyTransactionSummary.fulfilled, (state, action) => {
                state.loading = false
                state.dailyTransaction = action.payload;
            })
            .addCase(dailyTransactionSummary.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getTransactionsTotalSpend.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getTransactionsTotalSpend.fulfilled, (state, action) => {
                state.loading = false
                state.TransactionsTotalSpend = action.payload;
            })
            .addCase(getTransactionsTotalSpend.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(monthlySubscriptionSummary.pending, (state)=> {
                state.loading = true;
            })
            .addCase(monthlySubscriptionSummary.fulfilled, (state, action) => {
                state.loading = false
                state.monthlySubscription = action.payload;
            })
            .addCase(monthlySubscriptionSummary.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(dailySubscriptionSummary.pending, (state)=> {
                state.loading = true;
            })
            .addCase(dailySubscriptionSummary.fulfilled, (state, action) => {
                state.loading = false
                state.dailySubscription = action.payload;
            })
            .addCase(dailySubscriptionSummary.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getSubscriptionsEstimatedCostAndApplications.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getSubscriptionsEstimatedCostAndApplications.fulfilled, (state, action) => {
                state.loading = false
                state.SubscriptionsEstimatedCostAndApplications = action.payload;
            })
            .addCase(getSubscriptionsEstimatedCostAndApplications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getStatisticSubscriptionsByYear.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getStatisticSubscriptionsByYear.fulfilled, (state, action) => {
                state.loading = false
                state.statisticSubscriptions = action.payload;
            })
            .addCase(getStatisticSubscriptionsByYear.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getStatisticYearByUserId.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getStatisticYearByUserId.fulfilled, (state, action) => {
                state.loading = false
                state.statisticYear = action.payload;
            })
            .addCase(getStatisticYearByUserId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getMonthlySubscriptionsStatisticByUserId.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getMonthlySubscriptionsStatisticByUserId.fulfilled, (state, action) => {
                state.loading = false
                state.monthlySubscriptionsStatistic = action.payload;
            })
            .addCase(getMonthlySubscriptionsStatisticByUserId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getStatisticSubscriptionsByMonth.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getStatisticSubscriptionsByMonth.fulfilled, (state, action) => {
                state.loading = false
                state.byMonthlySubscriptionStatistic = action.payload;
            })
            .addCase(getStatisticSubscriptionsByMonth.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },

});

export default StatisticSlice.reducer;