import fetch from 'auth/FetchInterceptor'

const IntegrationService = {}

IntegrationService.getAllBanks = function () {
    return fetch({
        url: '/Bank/get-all',
        method: 'get'
    })
}

IntegrationService.getAuthLinkMonobank = function () {
    return fetch({
        url: '/Monobank/get-auth-link',
        method: 'get'
    })
}

IntegrationService.authorizeUserMonobank = function () {
    return fetch({
        url: '/Monobank/authorize-user',
        method: 'post'
    })
}

IntegrationService.getPendingSubscription = function () {
    return fetch({
        url: '/Subscription/get-subscriptions-accept?isShowHidden=true&isShowNotHidden=true',
        method: 'get'
    })
}

IntegrationService.putStatusesSubscription = function (data) {
    return fetch({
        url: '/Subscription/change-statuses-subscription',
        method: 'put',
        data: data
    })
}

IntegrationService.activeMonobank = function () {
    return fetch({
        url: '/Monobank/active-monobank',
        method: 'put'
    })
}

IntegrationService.checkMonobankAuth = function () {
    return fetch({
        url: '/Monobank/check-authorization-status',
        method: 'get'
    })
}

// AddSubscriptionService.putDetailsSubscriptions = function (data) {
//     return fetch({
//         url: '/CustomSubscription/edit-details',
//         method: 'put',
//         data: data
//     })

export default IntegrationService;