import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import NotificationsService from "../../services/NotificationsService";
import {
    authorizeUserMonobank,
    checkMonobankAuth,
    getAllBanks,
    getAuthLinkMonobank,
    getPendingSubscription, putStatusesSubscription
} from "./IntegrationSlice";


export const getRemindUser = createAsyncThunk(
    'get-remind/user',
    async (data, { rejectWithValue }) => {
        try {
            const response = await NotificationsService.getRemindUser();
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error fetching remind me');
        }
    }
);


const NotificationSlice = createSlice({
    name: 'notification',
    initialState: {
        notification: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(getRemindUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRemindUser.fulfilled, (state, action) => {
                state.loading = false;
                state.notification = action.payload;
            })

            .addCase(getRemindUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
})

export default NotificationSlice.reducer;