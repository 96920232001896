import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import application from './slices/applicationSlice'
import addSubscription from './slices/addSubscriptionSlice'
import services from "./slices/GetAllServiceSlice";
import financials from './slices/FinancialsSlice';
import profile from './slices/ProfileSlice';
import dashboard from './slices/DashboardSlice';
import statistic from './slices/StatisticSlice';
import integration from './slices/IntegrationSlice'
import notification from './slices/NotificationsSlice'
import googleWorkspace from './slices/googleWorkspaceSice'
import subscriptionRecordSlice from './slices/subscriptionRecordSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        application,
        addSubscription,
        services,
        financials,
        profile,
        dashboard,
        statistic,
        integration,
        notification,
        googleWorkspace,
        subscriptionRecordSlice,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
