import fetch from 'auth/FetchInterceptor'
const NotificationsService = {}

NotificationsService.getRemindUser = function() {
    return fetch({
        url: '/RemindMe/get-remind-user',
        method: 'get'
    })
}

export default NotificationsService;