import fetch from "../auth/FetchInterceptor";
const GoogleWorkspaceService = {}

GoogleWorkspaceService.postGoogleWorkspaceReports = function ({appAuthToken, googleApiToken, refreshGoogleApiToken}) {
    return fetch({
        url: `/GoogleWorkspace/post-reports-user?appAuthToken=${appAuthToken}&googleApiToken=${googleApiToken}&refreshGoogleApiToken=${refreshGoogleApiToken}`,
        method: 'post',
    })
}

GoogleWorkspaceService.setSubscriptionToActiveGoogleWorkspace = function (data) {
    return fetch({
        url: '/GoogleWorkspace/set-subscription-to-active-statuses-google-workspace',
        method: 'put',
        data: data
    })
}

GoogleWorkspaceService.setStatusActivatePendingApprovalSubscription = function ({subscriptionId, ActiveStatuses}) {
    return fetch({
        url: `/GoogleWorkspace/set-pending-approval-subscriptions-statuses-google-workspace?subscriptionId=${subscriptionId}&ActiveStatuses=${ActiveStatuses}`,
        method: 'put',
    })
}

GoogleWorkspaceService.getPendingSubscriptionsGoogleWorkspace = function () {
    return fetch({
        url: '/GoogleWorkspace/get-pending-subscriptions-google-workspace',
        method: 'get',
    })
}


GoogleWorkspaceService.getPendingApprovalSubscriptionsGoogleWorkspace = function () {
    return fetch({
        url: '/GoogleWorkspace/get-pending-approval-subscriptions-google-workspace',
        method: 'get',
    })
}

GoogleWorkspaceService.deactivateGoogleWorkspace = function () {
    return fetch({
        url: '/GoogleWorkspace/deactivate-google-workspace',
        method: 'put',
    })
}

export default GoogleWorkspaceService;
