import fetch from 'auth/FetchInterceptor'

const DashboardService = {}

DashboardService.GetDefault = function (data) {
    return fetch({
        url: '/Account/login',
        method: 'post',
        data: data
    })
}

DashboardService.getSubscriptionsStatisticDashboardByUserId = function (year) {
    return fetch({
        url: `/Subscription/get-subscriptions-statistic-dashboard-by-user-id?year=${year}`,
        method: 'get'
    })
}


export default DashboardService;